import './Gallery.css'
import UseWindowDimensions from '../../Hooks/UseWindowDimensions.js';

import "react-image-gallery/styles/css/image-gallery.css";
import ImageGallery from "react-image-gallery";

export default function Gallery() {
    const { width } = UseWindowDimensions();
    const images = [
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-1%2Foriginal.png?alt=media&token=2a5f6c44-6a68-42af-b3e1-59e2ebe4fbf7",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-1%2Fthumbnail.png?alt=media&token=39dd6488-5339-4155-8288-0ecbdf8d9a88",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-2%2Foriginal.png?alt=media&token=7bfd6c5b-4b02-4143-aa8f-1c6b9752ded6",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-2%2Fthumbnail.png?alt=media&token=af7e3f13-8a01-4ba8-9e9e-48a2c98cf8d2",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-3%2Foriginal.png?alt=media&token=c55a37cd-34b2-415e-9f39-7c373912c55a",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-3%2Fthumbnail.png?alt=media&token=5c857727-1b55-4002-a236-886d3b03beb3",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-4%2Foriginal.png?alt=media&token=9cad1cd8-df8a-4d2d-a027-3856e9911313",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-4%2Fthumbnail.png?alt=media&token=590b8e30-4d72-47b3-ac1c-1667ad72cb71",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-5%2Foriginal.png?alt=media&token=e177cf8e-cd6b-41fa-aa42-caeef955ed7f",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-5%2Fthumbnail.png?alt=media&token=dd9df556-a653-400b-b7ca-fa572f56c66e",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-6%2Foriginal.png?alt=media&token=b9f89d3e-09dd-435b-b2a7-9c1a9f5fc1b1",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-6%2Fthumbnail.png?alt=media&token=46e062fd-acc4-4d31-b396-be252b63a681",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-7%2Foriginal.png?alt=media&token=92f0cbd8-68d1-40d0-8489-026a7dfeb147",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-7%2Fthumbnail.png?alt=media&token=2606aadb-b256-49ef-8acb-c2e216047d35",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-8%2Foriginal.png?alt=media&token=b0b5a402-0c91-4acf-aa85-b5e75183a686",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-8%2Fthumbnail.png?alt=media&token=9d43d8fc-3efa-44c9-b919-bda99a46f1b8",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-9%2Foriginal.png?alt=media&token=b4ab28fa-0938-419b-a957-d312ff30793d",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-9%2Fthumbnail.png?alt=media&token=7de42184-356f-4c26-a38e-d80a230c5a82",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-10%2Foriginal.png?alt=media&token=4aab2559-29d5-44e0-b4dd-65fefbb53670",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-10%2Fthumbnail.png?alt=media&token=1e858aa0-cb3b-43db-8d6e-c97a4c5980ec",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-11%2Foriginal.png?alt=media&token=52a78afa-329e-4d13-9272-b12b569bf61c",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-11%2Fthumbnail.png?alt=media&token=469cfd10-7148-4bab-ae29-6d957f930d49",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-12%2Foriginal.png?alt=media&token=46f3c33d-5d01-4f91-a724-880dbd46e3e1",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-12%2Fthumbnail.png?alt=media&token=123d54e9-534d-4ea7-a25c-ddc642712bf0",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-13%2Foriginal.png?alt=media&token=7b2da4d9-54c4-4144-9980-36519dd98c66",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-13%2Fthumbnail.png?alt=media&token=94e84efc-0a51-4b1c-8c58-7f42c3a72dd3",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-14%2Foriginal.png?alt=media&token=2834fb7a-6f45-4718-a470-c98d7fdabafa",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-14%2Fthumbnail.png?alt=media&token=a7012aec-4498-4a2b-b43f-9ea4b6ec1571",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-15%2Foriginal.png?alt=media&token=87236594-2ed2-42e5-9bee-d0522fe298f0",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-15%2Fthumbnail.png?alt=media&token=d4d7689e-d267-42f0-9dd8-22df649cebe7",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-16%2Foriginal.png?alt=media&token=dd22a12f-6529-4ad7-ab47-c094bcc44054",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-16%2Fthumbnail.png?alt=media&token=79eb27ce-8c2f-416c-8c2e-9b7effc33311",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-17%2Foriginal.png?alt=media&token=3f270630-5acc-4ba2-80d8-21397197cfdf",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-17%2Fthumbnail.png?alt=media&token=5422d800-969b-40c3-88a6-d4d01a3d50f2",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-18%2Foriginal.png?alt=media&token=d28b0d14-ae18-46d7-a249-53e21f02287d",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-18%2Fthumbnail.png?alt=media&token=c3e5811c-ed3e-4ead-ba40-7b4a6f694e0e",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-19%2Foriginal.png?alt=media&token=dc2226d6-45c2-444d-acc4-91dcd34c86fd",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-19%2Fthumbnail.png?alt=media&token=934caa82-4075-4aa3-87e3-6665ab8071fa",
        },
        {
            original: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-20%2Foriginal.png?alt=media&token=d1bb1f7f-2cd2-4d85-8454-23ee78e008ab",
            thumbnail: "https://firebasestorage.googleapis.com/v0/b/gormanda-5662e.appspot.com/o/cakes%2Fgallery%2Fcake-20%2Fthumbnail.png?alt=media&token=f3eebb28-3a30-4b56-8b6f-9ba91e358cc8",
        },
    ]

    if (width > 700) {
        return (
            <section className="gallery-section">
                <div className="container">
                    <ImageGallery 
                        items={images}
                        thumbnailPosition="left"
                        lazyLoad={true}
                    />
                </div>
            </section>
        )
    } else {
        return (
            <section className="gallery-section">
                <div className="container">
                    <ImageGallery 
                        items={images}
                        thumbnailPosition="bottom"
                        lazyLoad={true}
                    />
                </div>
            </section>
        )
    }
}