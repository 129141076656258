import './LanguageSelector.css'

import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Cookies from 'js-cookie';

export default function LanguageSelector() {
    const languageDetector = new LanguageDetector();
    languageDetector.addDetector({
        name: 'cookie',
        lookup(options) {
            return Cookies.get('lang');
        },
    });
    
    function handleChangeLanguage(event) {
        const newLang = event.target.value;
        Cookies.set('lang', newLang);
        i18n.changeLanguage(newLang);
    }

    const languages = [
        {
            key: "cat",
            name: "Català"
        },
        {
            key: "en",
            name: "English"
        },
        {
            key: "es",
            name: "Español"
        }
    ]

    const currentLanguage = Cookies.get('lang')

    return (
        <div className="change-language-container">
            <select defaultValue={currentLanguage} className="custom-select" onChange={handleChangeLanguage}>
                {
                    languages.map(function(language){
                        return (
                            <option key={language.key} className={language.key} value={language.key}>{language.name}</option>
                        );
                    })
                }
            </select>
        </div>
    )
  }
  